import React, { useMemo, useRef, useState, useEffect } from 'react';
import * as THREE from 'three';
import PausePlayButton from './PausePlayButton';
import url from './video2.mp4';
import { extend } from '@react-three/fiber';
import { geometry } from 'maath';

extend(geometry);

const CurvedPlane = ({ width, height, radius, ...props }) => {
  const videoRef = useRef(null);

  const [video] = useState(() => {
    const vid = document.createElement('video');
    vid.preload = 'metadata';
    vid.src = url;
    vid.playsInline = true;
    vid.crossOrigin = 'Anonymous';
    vid.loop = true;
    vid.muted = true;
    vid.className = 'lazy';
    videoRef.current = vid;
    return vid;
  });

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const frameNumber = 880; // Example frame number
      const frameRate = 30; // Assuming the video frame rate is 30fps
      const seekTime = frameNumber / frameRate;
      videoRef.current.currentTime = seekTime;
      videoRef.current.pause();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

      // Cleanup event listener on component unmount
      return () => {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoRef]);

  return (
    <group {...props}>
      <mesh receiveShadow castShadow>
        <roundedPlaneGeometry args={[width, height, radius]} />
        <meshStandardMaterial side={THREE.DoubleSide}>
          <videoTexture
            attach="map"
            args={[video]}
            wrapS={THREE.RepeatWrapping}
            wrapT={THREE.RepeatWrapping}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
            
          />
          <videoTexture
            attach="emissiveMap"
            args={[video]}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
          />
        </meshStandardMaterial>
      </mesh>
      <PausePlayButton 
        video={video} 
        positionOffset={[5, 2.7, 0.3]} 
        imgPathPlay="images/play button.svg" 
        imgPathPause="images/pause button.svg" 
        scale={[0.3, -0.3, 0.1]} 
      />
    </group>
  );
};

export default CurvedPlane;
