import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa6";
import Dropdown from "./DropDownMenu";
import AnimatedButton from "./AnimatedButton";

export default function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className={`frame container ${isScrolled ? "scrolled" : ""}`}>
      <div className="frame__title">
        <a href="https://memotics.io/">
          <img src="images/logo.webp" alt="Logo" className="frame__title-logo" />
        </a>
        {/* <h1 className="frame__title-main">
          MDS <a href="https://www.memotics.io/">by Memotics</a>
        </h1> */}
      </div>

      <a className="topnav">
        <AnimatedButton />
        <Dropdown />
      </a>
    </div>
  );
}