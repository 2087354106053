import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function HomieModel(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/glb/Homie_Optimized.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <mesh
          name="connector"
          castShadow
          receiveShadow
          geometry={nodes.connector.geometry}
          material={materials.base}
          position={[0, 0.202, -0.015]}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/glb/Homie_Optimized.glb')