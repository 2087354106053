import React, { Suspense, useRef, useState, useContext, useEffect, lazy, useCallback } from "react";
import { GlobalCanvas, SmoothScrollbar } from "@14islands/r3f-scroll-rig";
import { Environment, Loader } from "@react-three/drei";
import "@14islands/r3f-scroll-rig/css";
import { BrowserView, isIOS, MobileView, isBrowser, isMobile } from 'react-device-detect';

import { BodyCopy, Headline, Subtitle } from "./Text";
import { WebGLBackground } from "./WebGLBackground";
import { Lens } from "./Lens";
import CodropsFrame from "./CodropsFrame";

import BoySection from "./BoySection"
import CloudScroll from "./CloudScroll";
import VideoGallery from "./VideoGallery";
import SunBall from "./SunBall";
import { initFluid } from './CursorFluid';

import DifferentByDesign from "./DifferentByDesign";
import Immersively from "./Immersively";
import SunspotLoader from "./sunspotloader";
import ParticleSystem from "./ParticleSystem";
const HeadApp = lazy(() => import('./HeadApp'));
const CloudScrollIcon = lazy(() => import('./CloudScrollIcon'));
const SvgAnimation1 = lazy(() => import('./SvgAnimation1'));
const SvgAnimation2 = lazy(() => import('./SvgAnimation2'));

const ClearStorageAndCache = () => {
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    indexedDB.databases().then((dbs) => {
      dbs.forEach((db) => {
        indexedDB.deleteDatabase(db.name);
      });
    });
    if ('caches' in window) {
      caches.keys().then((names) => {
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);
  return null;
};

export default function App() {
  const eventSource = useRef();
  const fluidCanvasRef = useRef(null);
  const displacementCanvasRef = useRef(null);
  const [enabled, setEnabled] = useState(true);
  const targetRef = useRef(null);
  const cloudSectionRef = useRef(null);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [scrollEventEnabled, setScrollEventEnabled] = useState(true);
  const [smoothScrollbarEnabled, setSmoothScrollbarEnabled] = useState(true);

  const initializeFluidAndDisplacement = useCallback(() => {
    if (fluidCanvasRef.current) {
      initFluid(fluidCanvasRef.current);
    }
  }, []);

  useEffect(() => {
    initializeFluidAndDisplacement();
  }, [initializeFluidAndDisplacement]);

  const smoothScrollToBottom = (duration) => {
    setSmoothScrollbarEnabled(false);
    setIsAutoScrolling(true);
    setScrollEventEnabled(false);

    const start = window.pageYOffset || document.documentElement.scrollTop;
    const startTime = performance.now();

    const animateScroll = (currentTime) => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easeInOutCubic = progress < 0.5
        ? 4 * progress * progress * progress
        : 1 - Math.pow(-2 * progress + 2, 3) / 2;

      const documentHeight = Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
      );
      const windowHeight = window.innerHeight;
      const target = documentHeight - windowHeight;
      const change = target - start;

      window.scrollTo(0, start + change * easeInOutCubic);

      if (progress < 1 && window.pageYOffset < target) {
        requestAnimationFrame(animateScroll);
      } else {
        setIsAutoScrolling(false);
        setScrollEventEnabled(true);
        setSmoothScrollbarEnabled(true);
      }
    };

    requestAnimationFrame(animateScroll);
  };

  useEffect(() => {
    const handleWheel = (event) => {
      if (!scrollEventEnabled) return;

      const targetElement = targetRef.current;
      if (!targetElement) return;

      const rect = targetElement.getBoundingClientRect();
      const isInView = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isInView && !isAutoScrolling) {
        if (event.deltaY > 0) {
          smoothScrollToBottom(1000);
        }
      }
    };

    window.addEventListener('wheel', handleWheel);

    return () => {
      window.removeEventListener('wheel', handleWheel);
    };
  }, [isAutoScrolling, scrollEventEnabled]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash === '#CloudSection') {
        smoothScrollToBottom(1000);
      }
    };

    handleHashChange();

    window.addEventListener('hashchange', handleHashChange);

    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, []);

  return (
    <>
      <ClearStorageAndCache />
      <div ref={eventSource}>
        <div className="gradientBackground"></div>
        <div className="BlackgradientBackground"></div>
        <CodropsFrame />

        {smoothScrollbarEnabled && (
          <SmoothScrollbar
            enabled={enabled}
            config={{ syncTouch: true }}
          />
        )}
        <article>
          <header className="container">
            <div className="headerLayout">
              <h2 id="headerh2">
                We architect world class solutions to weave your brand story with immersive Web Magic.
              </h2>
            </div>
          </header>

          <div className="headAppContainer">
            <Suspense fallback="">
              <HeadApp />
            </Suspense>
            <p className="scrollToExplore">
              SCROLL TO EXPLORE
            </p>
          </div>

          <section className="container">
            <div className="title">
              <h2 id="titleh2">
                Bridging Realities: Immersive 3D Platform for All
              </h2>
            </div>
            <div className="bodyCopyVideoBall">
              <p>
                Memotics Design Studio: a Service-based Tech Enabler to integrate advanced technologies and create immersive experiences for companies.
              </p>
              <p>
                We are architects, digital artists, and entrepreneurs that empowers you to break through the noise, access world-class tools, and captivate global audiences, fostering success in your industry.
              </p>
              <button href="https://memotics.io/AboutUs/" className="aboutUsBtn"><a href="https://memotics.io/AboutUs/">About Us</a></button>
            </div>

            <div className="videoBallEffect">
              <section className="Sunball">
                <SunBall />          
              </section>
              <div className="text-overlay">video we like by CD Kota Iguchi</div>
            </div>
          </section>

          <div className="parentDiv">
          </div>
          <section className="container">
            <div className="section3">
              <h2>
                Different by Design. Built for creatives by Creatives.
              </h2>
            </div>
          </section>

          <div id='BoySection'>
            <BoySection />
          </div>
          <section className="container" id="ready">
            <h3 id="readyToRevo">
              Ready to revolutionize
              <br />your digital presence?
            </h3>

            <section className="videoGallerySection">
              <VideoGallery />          
            </section>
          
            <h3 id="dontJustCreate">
              <em>
                Don't just create experiences captivate your audience with our unique new physical reality.
              </em>
            </h3>
          </section>

          <h3 id="target2" className="heading2" ref={targetRef}>
            <span className="font-10 font-upper"> Immersive Web Solutions Suite ↓</span>
          </h3>

          <Suspense fallback=''>
            <SvgAnimation1 />
          </Suspense>

          <Suspense fallback=''>
            <SvgAnimation2 />
          </Suspense>

          <div id="CloudSection" ref={cloudSectionRef}>
            <CloudScroll />
          </div>

          <div id="warning-message" className="rotate">
            <img src="images/rotate.webp" width="100%" alt="Rotate your device" />
          </div>

        </article>

        <Loader
          containerStyles={{
            background: "#ecf3f6",
            top: "auto",
            bottom: 0,
            height: "100%",
            width: "100%",
            position: "fixed",
            justifyContent: "center",
            alignItems: "center"
          }}
          innerStyles={{ background: "transparent", width: "40vh", height: "105px" }}
          barStyles={{ background: 'linear-gradient(to right bottom, #ff4d4d, #fed1c7)', height: "30%", borderRadius: "100px" }}
          dataStyles={{ color: 'ff4d4d', fontSize: '14px', fontFamily: "Poppins, sans-serif" }}
          dataInterpolation={(p) => `Try to Scroll and Drag ${p.toFixed(2)}%`}
          initialState={(active) => active}
        />
      </div>
      <canvas id="fluid" ref={fluidCanvasRef}></canvas>
    </>
  );
}