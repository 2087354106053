import { Canvas, useFrame } from "@react-three/fiber";
import { Suspense, useEffect, useState, useRef, useReducer, useMemo, lazy, useContext } from "react";

import { BoyModel } from "./BoyModel";
import AeroplaneModel from "./AeroplaneModel";
import ScreenModel from "./RoundedScreenModel";
import OrgModel4 from "./OrgModel4";

import React from 'react';
import { easing } from 'maath'
import * as THREE from 'three'
import { useGLTF, MeshTransmissionMaterial, Environment, Lightformer, useTexture, Sphere, Float, Text3D, ScrollControls, useScroll, OrbitControls, PerspectiveCamera, Loader, Bvh } from '@react-three/drei'
import { CuboidCollider, BallCollider, Physics, RigidBody } from '@react-three/rapier'
import ImageClickableButton from "./ImageClickableButton";
import { useInView } from "react-intersection-observer";
import { Html } from "@react-three/drei"; //Camera Controls
import { StoredData } from "../VirtualTour/Context";
import { HomieModel } from "./HomieModel";
import { TModel } from "./TesserectModel";

import { isIOS } from "react-device-detect";
import Coldchain from "../ColdChainBall";
// import BoyFly from "./BoyFly";
// import BoyFly2 from "./BoyFly2";
import VirtualButton from "./VirtualButton"; 
import FocusButton from "./FocusButton"; 
import CurvedPlane from "./CurvedPlane";
import CustomRoundedPlane from "./CustomRoundedPlane";



function MoveColdChain() {
  // location.href = 'https://memotics.io/ColdChain/';
}


const accents = ['#4060ff', '#20ffa0', '#ff4060', '#ffcc00']
const shuffle = (accent = 0) => [
  { color: '#444', roughness: 0.1 },
  { color: '#444', roughness: 0.75 },
  { color: '#444', roughness: 0.75 },
  { color: 'white', roughness: 0.1 },
  { color: 'white', roughness: 0.75 },
  { color: 'white', roughness: 0.1 },
  { color: accents[accent], roughness: 0.1, accent: true },
  { color: accents[accent], roughness: 0.75, accent: true },
  { color: accents[accent], roughness: 0.1, accent: true }
]

const SpinModel = () => {
  const boxRef = useRef();
  useFrame((state, delta) => {
    boxRef.current.rotation.y -= 0.01;
    if (boxRef.current.rotation.y <= -(Math.PI * 2)) {
      boxRef.current.rotation.y = 0;
    }
  });

  return (
    <>
      <mesh ref={boxRef} rotation-y={Math.PI * 0.25} position={[0, 5, 200]} scale={60}>
        {/* <Suspense fallback=""> */}
        <OrgModel4 />
        {/* </Suspense> */}
      </mesh>
    </>
  )
}

function MoveToGame() {
  location.href = 'https://memotics.io/YTSUGame/';
}
const lerpVector = (start, end, alpha) => {
  return new THREE.Vector3(
    THREE.MathUtils.lerp(start.x, end.x, alpha),
    THREE.MathUtils.lerp(start.y, end.y, alpha),
    THREE.MathUtils.lerp(start.z, end.z, alpha)
  );
};
const getFrontPosition = (target, camera, distance) => {
  const direction = new THREE.Vector3().subVectors(camera.position, target).normalize();
  const frontPosition = target.clone().add(direction.multiplyScalar(distance));
  frontPosition.y = target.y; // Set the camera height to the target's height (base level)
  frontPosition.y -= 20;
  return frontPosition;
};

const easeInOutCubic = (t) => {
  return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
};

function CloudSpace() {
  const [accent, click] = useReducer((state) => ++state % accents.length, 0);
  const connectors = useMemo(() => shuffle(accent), [accent]);
  const { ref, inView } = useInView();
  console.log("Current canvas inView?" + inView)
  const [isEndOfPage, setIsEndOfPage] = useState(false);

  const [showResetButton, setShowResetButton] = useState(true);
  const [hideResetButton, setHideResetButton] = useState(false);

  const controlsRef = useRef();
  const [isFocused, setIsFocused] = useState(false);
  const [previousTarget, setPreviousTarget] = useState(new THREE.Vector3(0, 0, 0));
  
  const initialCameraPosition = new THREE.Vector3(0, 200, -600);
  const initialCameraTarget = new THREE.Vector3(0, 0, 0);


  const handleResetClick = () => {
    setHideResetButton(true);
    setTimeout(() => {
      setShowResetButton(false);
      setHideResetButton(false);
    }, 500); // Match the duration of the fade-out transition
    setTarget(initialCameraPosition, 0, 2.5, true);
  };
  const setTarget = (targetPosition, distance = 70, duration = 2.5, reset = false, positionOffset = new THREE.Vector3(0, 0, 0)) => {
    const camera = controlsRef.current.object;
    const start = camera.position.clone();
    const adjustedTargetPosition = targetPosition.clone().add(positionOffset);
    const end = reset ? targetPosition : getFrontPosition(adjustedTargetPosition, camera, distance);
    const originalTarget = controlsRef.current.target.clone();
    const finalTarget = reset ? initialCameraTarget : adjustedTargetPosition.clone();
  
    if (!reset) finalTarget.y -= 20;
  
    let startTime = null;
  
    const animate = (time) => {
      if (!startTime) startTime = time;
      const elapsedTime = (time - startTime) / 1000;
      const t = Math.min(elapsedTime / duration, 1);
      const alpha = easeInOutCubic(t);
  
      camera.position.copy(lerpVector(start, end, alpha));
      controlsRef.current.target.copy(lerpVector(originalTarget, finalTarget, alpha));
      controlsRef.current.update();
  
      if (t < 1) {
        requestAnimationFrame(animate);
      } else {
        setPreviousTarget(adjustedTargetPosition);
        if (reset) {
          setIsFocused(false);
          setTimeout(() => {
            controlsRef.current.update();
          }, 0);
        } else {
          setShowResetButton(true); // Ensure the reset button shows up when focusing on a new target
        }
      }
    };
  
    requestAnimationFrame(animate);
  };
  

  
  useEffect(() => {
    if (controlsRef.current) {
      controlsRef.current.object.position.copy(initialCameraPosition);
      controlsRef.current.target.copy(initialCameraTarget);
      controlsRef.current.update();
    }
  }, []);
  
  
  useEffect(() => {
    if (isIOS) {
      function handleScroll() {
        const windowHeight = window.innerHeight;
        const documentHeight = document.documentElement.scrollHeight;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        const scrollBottom = scrollTop + windowHeight;

        // Define the point where you want the redirection to occur
        const scrollThreshold = 0.92; // Adjust this value as needed

        // Calculate the position where the threshold is met
        const thresholdPosition = documentHeight * scrollThreshold;

        // Check if the user has scrolled beyond the threshold position
        if (scrollBottom >= thresholdPosition && !isEndOfPage) {
          setIsEndOfPage(true);
        }
      }
      // Add event listener for scrolling
      window.addEventListener('scroll', handleScroll);

      // Remove event listener when component unmounts
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isEndOfPage, isIOS]);

  useEffect(() => {
    // Redirect the user to another page if they continue scrolling beyond the bottom
    if (isEndOfPage && isIOS) {
      // Wait for 1.5 seconds before redirecting
      const timeoutId = setTimeout(() => {
        // Redirect logic here
        window.location.href = 'https://memotics.io/Clouds';
      }, 0);

      // Clear the timeout if the component unmounts or if isEndOfPage becomes false
      return () => clearTimeout(timeoutId);
    }
  }, [isEndOfPage, isIOS]);

  if (!isIOS)
    return (
      <>
      <div id="CloudSection" style={{ position: 'absolute', top: '0', width: '100%', height: '100%' }}>

        <Suspense fallback={null}>
          <Canvas
            ref={ref}
            onClick={click}
            shadows
            camera={{ position: [0, 200, -600], near: 20, far: 2000, fov: 55 }}
  dpr={[1, 2]}
            style={{ borderRadius: 30 }}
            // frameloop={inView ? 'auto' : 'never'}
            re
          >
            {/* ColdChain Ball Model */}
            <Float
              floatIntensity={2}
              speed={2}>
              <group position={[-19, -6.4, -39]} rotation={[0, Math.PI * 0.1, 0]} scale={5} onClick={MoveColdChain}>
                <Coldchain />
                {!isFocused && (
    <FocusButton
      positionOffset={[0, -3, 0]}
      imgPath="images/circlewithdotwhite.png"
      scale={[2, 2, 2]}
      onFocus={setIsFocused}
      onSetTarget={(targetPosition,distance ,duration, reset) => setTarget(targetPosition, distance, duration, reset, new THREE.Vector3(0, 40, 0))} // Lower the target by 10 units in the Y axis
      controlsRef={controlsRef}
    />
  )}
              </group>
            </Float>
{/* 
            <ImageClickableButton buttonName="Virtual Tour" buttonPosition={[110, 10, -40]} newSiteLink="VirtualTour" imgPath="images/tour_window.webp" />

            <ImageClickableButton buttonName="Living Room" buttonPosition={[50, 10, -70]} newSiteLink="LivingRoom" imgPath="images/room_window.webp" /> */}
       <group position={[110, 10, -40]}>
  <VirtualButton
    imgPath="images/tour_window.webp"
    // newSiteLink="https://memotics.io/Tour/"
    scale={[50, 50, 50]}
  />

  {!isFocused && (
    <FocusButton
      positionOffset={[0, 32, 0]}
      imgPath="images/circlewithdotwhite.png"
      scale={[10, 10, 10]}
      onFocus={setIsFocused}
      onSetTarget={setTarget}
      controlsRef={controlsRef}
    />
  )}
</group>
<group position = {[50, 10, -70]}>
<VirtualButton
            imgPath="images/room_window.webp"
            // newSiteLink="https://memotics.io/LivingRoom/"
            scale={[50, 50, 50]}
          />
{!isFocused && (
    <FocusButton
      positionOffset={[0, 32, 0]}
      imgPath="images/circlewithdotwhite.png"
      scale={[10, 10, 10]}
      onFocus={setIsFocused}
      onSetTarget={setTarget}
      controlsRef={controlsRef}
    />
  )}
</group>
          <OrbitControls 
            ref={controlsRef}
            maxDistance={200} 
            enableZoom={false} 
            enablePan={false} 
            minPolarAngle={0} 
            maxPolarAngle={Math.PI / 2} 
          />


            <group rotation={[0, 0.8, 0]}>
              <mesh position={[0, -17, 15]}>
                <SpinModel />

                
                <group scale={10} position={[-60, 10, -120]} onClick={MoveToGame}>
  <TModel />
  {!isFocused && (
    <FocusButton
      positionOffset={[0, 2, 0]}
      imgPath="images/circlewithdotwhite.png"
      scale={[1, 1, 1]}
      onFocus={setIsFocused}
      onSetTarget={setTarget}
      controlsRef={controlsRef}
    />
  )}
</group>



                {/* <group onClick={MoveToGame} scale={5}>
                    <HomieModel/>
                </group> */}

{/* <mesh position={[-90, 10, 0]} scale={30} rotation={[Math.PI * 0.5, THREE.MathUtils.degToRad(180), 0]}>
  <ScreenModel />
  {!isFocused && (
    <FocusButton
      positionOffset={[0, 0, -1.5]}  // This is for the FocusButton itself
      imgPath="images/circlewithdotwhite.png"
      scale={[.4, .4, 0.4]}
      onFocus={setIsFocused}
      onSetTarget={(targetPosition,distance, duration, reset) => setTarget(targetPosition, distance, duration, reset, new THREE.Vector3(0, 60, 0))} // Lower the target by 10 units in the Y axis
      controlsRef={controlsRef}
    />
  )}
</mesh> */}

<mesh position={[-90, 10, 0]} scale={10} rotation={[0, THREE.MathUtils.degToRad(90), 0]}>
                <CustomRoundedPlane width={12} height={6.5} radius={1} />
                {!isFocused && (
                  <FocusButton
                    positionOffset={[0, -4, 0]}
                    imgPath="images/circlewithdotwhite.png"
                    scale={[1, 1, 1]}
                    onFocus={setIsFocused}
                    onSetTarget={(targetPosition, distance, duration, reset) => setTarget(targetPosition, distance, duration, reset, new THREE.Vector3(0, 60, 0))}
                    controlsRef={controlsRef}
                  />
                )}
              </mesh>

                <mesh>
                  {/* <Suspense fallback=""> */}
                  <AeroplaneModel />
                  {/* </Suspense> */}
                  

                </mesh>
              </mesh>
            </group>

            <Environment
              background
              resolution={1024}
              files={
                "/textures/industrial_sunset_02_puresky_4k2.hdr"
              }
            />
            {/* </Bvh> */}
          </Canvas>
          <Loader />
   {/* This style can be found in public style.css */}
          {isFocused && showResetButton && (
            <div className={`button-container ${hideResetButton ? 'hidden' : ''}`} style={{ position: 'absolute', bottom: '130px', left: '50%', transform: 'translateX(-50%)', width: '50px', height: '50px' }}>
              <div className="fill"></div>
              <button onClick={handleResetClick} style={{ background: 'none', border: 'none', cursor: 'pointer', width: '100%', height: '100%', borderRadius: '50%', padding: 0 }}>
                <img src="images/XButtonWhite.png" alt="Reset Camera" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
              </button>
            </div>
          )}

        </Suspense>
        </div>
      </>
    );
  else return (
    <>
      <div className="toTheClouds" style={{ display: 'block' }}>
        <img src="./images/to the clouds.png" alt="" />
      </div>
    </>
  )
}

export default CloudSpace;
