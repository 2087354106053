import React, { useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
import url from './video2.mp4';
import * as THREE from 'three';
import PausePlayButton from './PausePlayButton';

const ScreenModel = (props) => {
  const { nodes, materials } = useGLTF('/Models/flat screen vertical.glb');
  const videoRef = useRef(null);

  const [video] = useState(() => {
    const vid = document.createElement('video');
    vid.preload = 'metadata'; // Preload metadata to ensure loadedmetadata event fires
    vid.src = url;
    vid.playsInline = true;
    vid.crossOrigin = 'Anonymous';
    vid.loop = true;
    vid.muted = true;
    vid.className = 'lazy';
    videoRef.current = vid;
    return vid;
  });

  useEffect(() => {
    const handleLoadedMetadata = () => {
      const frameNumber = 880; // Example frame number
      const frameRate = 30; // Assuming the video frame rate is 30fps
      const seekTime = frameNumber / frameRate;
      videoRef.current.currentTime = seekTime;
      videoRef.current.pause();
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);

      // Cleanup event listener on component unmount
      return () => {
        videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
      };
    }
  }, [videoRef]);

  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={nodes.Plane.material}
        rotation={[Math.PI / 2, 0, -Math.PI / 2]}
        scale={[1.08, 1, 1.92]}
      >
        <meshStandardMaterial side={THREE.DoubleSide}>
          <videoTexture
            attach="map"
            args={[video]}
            wrapS={THREE.RepeatWrapping}
            wrapT={THREE.RepeatWrapping}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
            rotation={Math.PI * 0.5}
          />
          <videoTexture
            attach="emissiveMap"
            args={[video]}
            format={THREE.RGBAFormat}
            minFilter={THREE.LinearFilter}
            magFilter={THREE.LinearFilter}
          />
        </meshStandardMaterial>
      </mesh>
      <PausePlayButton 
        video={video} 
        positionOffset={[-.1, -1.7, 0.9]} 
        imgPathPlay="images/play button.svg" 
        imgPathPause="images/pause button.svg" 
        scale={[0.1, -0.1, 0.2]} 
      />
    </group>
  );
};

export default ScreenModel;
useGLTF.preload('/Models/flat screen vertical.glb');
