import React, { useRef, useEffect, useState } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import SnowStorm from './SnowStorm';
import GodParticle from './GodParticle';
import videoFile from './Orb_Ref_R2.mp4';
import Stats from 'three/examples/jsm/libs/stats.module';

function ParticleSystem() {
  const containerRef = useRef(null);
  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000));
  const rendererRef = useRef(new THREE.WebGLRenderer({ antialias: true }));
  const snowStormRef = useRef(null);
  const godParticleRef = useRef(null);
  const videoPlaneMeshRef = useRef(null);
  const statsRef = useRef(new Stats());
  const [showStats, setShowStats] = useState(false);
  const showStatsRef = useRef(showStats);
  const [particlesVisible, setParticlesVisible] = useState(true);
  const [uncapFPS, setUncapFPS] = useState(false);
  const uncapFPSRef = useRef(uncapFPS);
  const lastTimeRef = useRef(0);
  const animationFrameIdRef = useRef(null);
  const [isInView, setIsInView] = useState(true);

  const [fixedTimeStep, setFixedTimeStep] = useState(1/30);
  const fixedTimeStepRef = useRef(1/30);

  const handleKeyDown = (event) => {
    if (event.key === 'f' || event.key === 'F') {
      console.log('Toggle showStats');
      setShowStats((prev) => !prev);
    }
    if (event.key === 'p' || event.key === 'P') {
      setParticlesVisible((prev) => !prev);
    }
    if (event.key === '[') {
      console.log('Toggle uncapFPS');
      setUncapFPS((prev) => !prev);
    }
    if (event.key === ']') {
      console.log('Cycle fixedTimeStep');
      setFixedTimeStep((prev) => {
        if (prev === 1/30) return 1/60;
        if (prev === 1/60) return 1/100;
        return 1/30;
      });
    }
  };

  let accumulator = 0;
  
  const animate = (time) => {
    if (!isInView) {
      animationFrameIdRef.current = requestAnimationFrame(animate);
      return;
    }

    const deltaTime = Math.min((time - lastTimeRef.current) / 1000, 0.1);
    lastTimeRef.current = time;
    accumulator += deltaTime;
  
    if (uncapFPSRef.current) {
      animationFrameIdRef.current = setTimeout(() => animate(performance.now()), 0);
    } else {
      animationFrameIdRef.current = requestAnimationFrame(animate);
    }
  
    if (showStatsRef.current) {
      statsRef.current.begin();
    }
  
    while (accumulator >= fixedTimeStepRef.current) {
      if (particlesVisible) {
        snowStormRef.current.update(fixedTimeStepRef.current);
        godParticleRef.current.update(fixedTimeStepRef.current);
      }
      accumulator -= fixedTimeStepRef.current;
    }
  
    if (videoPlaneMeshRef.current) {
      videoPlaneMeshRef.current.quaternion.copy(cameraRef.current.quaternion);
    }
  
    rendererRef.current.render(sceneRef.current, cameraRef.current);
  
    if (showStatsRef.current) {
      statsRef.current.end();
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        const newIsInView = entry.isIntersecting;
        setIsInView(entry.isIntersecting);
        console.log(`ParticleSystem is ${newIsInView ? 'in view' : 'out of view'}`);
      },
      {
        threshold: 0.1
      }
    );

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    console.log('Initial showStats value:', showStats);

    if (!mountRef.current) return;

    rendererRef.current.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(rendererRef.current.domElement);

    const controls = new OrbitControls(cameraRef.current, rendererRef.current.domElement);
    controls.enableDamping = true;
    controls.enableZoom = false;

    statsRef.current.showPanel(0);
    statsRef.current.domElement.style.position = 'fixed';
    statsRef.current.domElement.style.left = '10px';
    statsRef.current.domElement.style.top = '10px';
    document.body.appendChild(statsRef.current.domElement);

    snowStormRef.current = new SnowStorm({
      particleCount: 5000,
      clusterCount: 500,
      particleSizeMin: 0.05,
      particleSizeMax: 0.09,
      velocityFactor: 0.005,
      shrinkRateMin: 0.001,
      shrinkRateMax: 0.02,
      emissiveChance: 0.1,
      clusterRadius: 9,
      speed : 0.1,
    });
    snowStormRef.current.renderer = rendererRef.current;
    sceneRef.current.add(snowStormRef.current.getObject3D());

    godParticleRef.current = new GodParticle({
      particleCount: 20000,
      radius: 5,
      speed: 1,
      particleSize: 0.02,
      waveiness: 0.4,
      globalSpeed: 500,
      particleColor: new THREE.Color(0xf0f0f0),
      brightness: 100,
      showCenterSphere: false,
      paused: false,
    });
    sceneRef.current.add(godParticleRef.current.getObject3D());

    const video = document.createElement('video');
    video.src = videoFile;
    video.loop = true;
    video.muted = true;
    video.playsInline = true;
    video.autoplay = true;

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.format = THREE.RGBAFormat;

    const setupVideoPlane = (video) => {
      const aspectRatio = video.videoWidth / video.videoHeight;
      const planeWidth = 20;
      const planeHeight = planeWidth / aspectRatio;

      const planeGeometry = new THREE.PlaneGeometry(planeWidth, planeHeight);
      const planeMaterial = new THREE.MeshBasicMaterial({
        map: videoTexture,
        side: THREE.DoubleSide,
      });
      const videoPlane = new THREE.Mesh(planeGeometry, planeMaterial);
      videoPlaneMeshRef.current = videoPlane;

      sceneRef.current.add(videoPlane);
    };

    video.addEventListener('loadedmetadata', () => {
      setupVideoPlane(video);
      video.play();
    });

    cameraRef.current.position.set(0, 0, 10);

    lastTimeRef.current = performance.now();
    animate(lastTimeRef.current);
    
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;

      cameraRef.current.aspect = width / height;
      cameraRef.current.updateProjectionMatrix();

      rendererRef.current.setSize(width, height);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('keydown', handleKeyDown);
      if (mountRef.current) {
        mountRef.current.removeChild(rendererRef.current.domElement);
      }
      if (statsRef.current.domElement) {
        document.body.removeChild(statsRef.current.domElement);
      }
      if (animationFrameIdRef.current) {
        if (uncapFPSRef.current) {
          clearTimeout(animationFrameIdRef.current);
        } else {
          cancelAnimationFrame(animationFrameIdRef.current);
        }
      }
    };
  }, []);

  useEffect(() => {
    showStatsRef.current = showStats;
    if (statsRef.current) {
      statsRef.current.domElement.style.display = showStats ? 'block' : 'none';
    }
  }, [showStats]);

  useEffect(() => {
    uncapFPSRef.current = uncapFPS;
  }, [uncapFPS]);

  useEffect(() => {
    if (snowStormRef.current) {
      snowStormRef.current.getObject3D().visible = particlesVisible;
    }
    if (godParticleRef.current) {
      godParticleRef.current.getObject3D().visible = particlesVisible;
    }
  }, [particlesVisible]);

  useEffect(() => {
    fixedTimeStepRef.current = fixedTimeStep;
    console.log(`Fixed time step changed to: ${fixedTimeStep.toFixed(3)}`);
  }, [fixedTimeStep]);

  return (
    <div ref={containerRef} className="three-canvas-container">
      <div ref={mountRef} className="three-canvas" />
    </div>
  );
}

export default ParticleSystem;