import React, { useRef, useEffect } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function BoyModel(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/glb/boy_float.glb')
  const { actions, ref, names } = useAnimations(animations, group)

  function playAnims(actionArray) {
    actions[actionArray].timeScale = 1.2;
    actions[actionArray].reset().fadeIn(0.5).play();
  }

  useEffect(() => {
    names.forEach(playAnims)
  }, []);
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="BezierCircle">
          <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <skinnedMesh
              name="Ch09"
              geometry={nodes.Ch09.geometry}
              material={materials['Ch09_body.001']}
              skeleton={nodes.Ch09.skeleton}
            />
            <primitive object={nodes.mixamorig6Hips} />
          </group>
        </group>
      </group>
    </group>
  )
}


useGLTF.preload('/glb/boy_float.glb')
